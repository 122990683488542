<template>
    <section id="content">
        <div class="container">
            <div class="c-header">
                <h2>
                    Edit Staff Member
                    <small>Fields mark <strong>*</strong> are required </small>
                </h2>
            </div>
            <div class="card" id="profile-main">
                <div class="pm-overview c-overflow">
                    <div class="pmo-pic">
                        <div class="p-relative">

                            <croppa v-model="newMyCroppa" ref="editStaffPhoto"
                                :width="260"
                                :height="260"
                                canvas-color="transparent">
                                <img :src="staff.photo" alt="." slot="initial" crossorigin="anonymous">
                            </croppa>

                            <button class="btn btn-block btn-info" @click="selectPhoto" v-if="!isFileChoosen">
                                Select Photo
                            </button>

                            <button class="btn btn-block btn-success" @click="savePhoto">Save Photo</button>
                            
                        </div>
                    </div>
                </div>
                <form @submit.prevent="updateStaff">
                    <div class="pm-body clearfix">
       
                        <!-- Basic -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-account m-r-5"></i> Basic Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Salutation</dt>
                                    <dd>
                                        <div class="fg-line">
                                            <select class="form-control" v-model="staff.title" required>
                                                <option v-for="title in $store.state.salutations" :key="title">{{ title }}</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">First Name</dt>
                                    <dd>
                                        <input type="text" class="form-control" v-model="staff.first_name" required>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Last Name</dt>
                                    <dd>
                                        <input type="text" class="form-control" v-model="staff.last_name" required>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Other Names</dt>
                                    <dd>
                                        <input type="text" class="form-control" v-model="staff.other_names">
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Gender</dt>
                                    <dd>
                                        <div class="fg-line">
                                            <select class="form-control" v-model="staff.gender" required>
                                                <option>Male</option>
                                                <option>Female</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Date of Birth</dt>
                                    <dd>
                                        <birth-datepicker 
                                            v-model="staff.dob" 
                                            class="form-control" 
                                            :high="false" 
                                            :closeOnSet="true" 
                                            :valueIsString="true" 
                                            :delimiter="'-'" 
                                            :attachment="'bottom-right'"/>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Martial Status</dt>
                                    <dd>
                                        <div class="fg-line">
                                            <select class="form-control" v-model="staff.maritalStatus" required>
                                                <option v-for="status in $store.state.maritalStatuses" :key="status">{{ status }}</option>
                                            </select>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal" v-if="staff.maritalStatus == 'Married'">
                                    <dt class="p-t-10">Marriage Anniversary</dt>
                                    <dd>
                                        <birth-datepicker 
                                            v-model="staff.marriageAnniversary" 
                                            class="form-control" 
                                            :high="false" 
                                            :closeOnSet="true" 
                                            :valueIsString="true" 
                                            :delimiter="'-'" 
                                            :attachment="'bottom-right'"/>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal" v-if="staff.maritalStatus == 'Married'">
                                    <dt class="p-t-10">Name of Spouse</dt>
                                    <dd>
                                        <input type="text" class="form-control" v-model="staff.spouse">
                                    </dd>
                                </dl>
                            </div>
                        </div>

                        <!-- Contact -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-phone m-r-5"></i> Contact Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="pmbb-view">
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Mobile Phone 1</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <vue-tel-input @onInput="onInputTelephone1" mode="international" :enabledFlags="true" :enabledCountryCode="false" :disabledFormatting="true" wrapperClasses="telinput" inputId="telephone1" placeholder="" v-model="staff.telephone_1" required></vue-tel-input>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Mobile Phone 2</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <vue-tel-input @onInput="onInputTelephone2" mode="international" :enabledFlags="true" :enabledCountryCode="false" :disabledFormatting="true" wrapperClasses="telinput" inputId="telephone2" placeholder="" v-model="staff.telephone_2"></vue-tel-input>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Email Address 1</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <input type="email" class="form-control" v-model="staff.email_1" required>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Email Address 2</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <input type="email" class="form-control" v-model="staff.email_2">
                                            </div>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Employment -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-account m-r-5"></i> Employment Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="form-group m-b-30">
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Category</dt>
                                        <dd>
                                            <select class="form-control" v-model="staff.category" required>
                                                <option v-for="category in $store.state.categories" :key="category">{{ category }}</option>
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Assignment</dt>
                                        <dd>
                                            <select class="form-control" v-model="staff.assignment" required>
                                                <option v-for="assignment in $store.state.staffAssignments" :key="assignment">{{ assignment }}</option>
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Region of Assignment</dt>
                                        <dd>
                                            <select class="form-control" v-model="staff.region_assigned" required>
                                                <option v-for="region in $store.state.regionList" :key="region">{{ region }}</option>
                                            </select>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">City of Assignment</dt>
                                        <dd>
                                            <input type="text" class="form-control" v-model="staff.city_assigned">
                                        </dd>
                                    </dl>                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Date Joined</dt>
                                        <dd>
                                            <birth-datepicker  required
                                                v-model="staff.date_joined" 
                                                class="form-control" 
                                                :high="false" 
                                                :closeOnSet="true" 
                                                :valueIsString="true" 
                                                :delimiter="'-'" 
                                                :attachment="'bottom-right'"/>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">SSNIT Number</dt>
                                        <dd>
                                            <input type="text" class="form-control" v-model="staff.ssnit_number">
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>

                        <!-- Leadership Roles -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2>
                                    <i class="zmdi zmdi-edit m-r-5"></i>
                                    Leadership Roles
                                </h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="form-group m-b-30" v-for="(role, index) in staff.roles" :key="index">
                                
                                    <button type="button" class="btn btn-danger btn-icon btn-remove" @click="removeRole(role.id, index)" >
                                        <i class="zmdi zmdi-close"></i>
                                    </button>
                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Role</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <select class="form-control" v-model="role.role">
                                                    <option v-for="role in $store.state.roles" :key="role">{{ role }}</option>
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>
                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Date Started</dt>
                                        <dd>
                                            <vue-monthly-picker 
                                                class="form-control" 
                                                :dateFormat="'MM-YYYY'"
                                                :clearOption="false"
                                                :monthLabels="monthLabels"
                                                v-model="role.started"></vue-monthly-picker>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Is Current Role?</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <div class="toggle-switch m-t-10">
                                                    <input :id="'current-' + index" type="checkbox" hidden="hidden" v-model="role.isCurrent">
                                                    <label :for="'current-' + index" class="ts-helper"></label>
                                                </div>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal" v-if="!role.isCurrent">
                                        <dt class="p-t-10">Date Ended</dt>
                                        <dd>
                                            <vue-monthly-picker 
                                                class="form-control" 
                                                :dateFormat="'MM-YYYY'"
                                                :clearOption="false"
                                                :monthLabels="monthLabels"
                                                v-model="role.ended"></vue-monthly-picker>
                                        </dd>
                                    </dl>
                                    
                                </div>
                                <button type="button" class="btn btn-primary btn-sm" @click="addNewRole">Add New Position</button>
                            </div>
                        </div>

                        <!-- Identification -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-account m-r-5"></i> Identification Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Current Passport</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.passport_number" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.passport_expiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Ghana card</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.ghana_card_number" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.ghana_card_expiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Voters ID</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.voters_id_number" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.voters_id_expiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                                <dl class="dl-horizontal">
                                    <dt class="p-t-10">Health Insurance No</dt>
                                    <dd>
                                        <div class="row">
                                            <div class="col-md-7">
                                                <input v-model="staff.health_insurance_number" type="text" class="form-control" placeholder="Number">
                                            </div>
                                            <div class="col-md-5">
                                                <datetime v-model="staff.health_insurance_expiry" format="dd-MM-yyyy" class="form-control" placeholder="Expiry Date"></datetime>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>                        

                        <!-- Children -->
                        <div class="pmb-block">
                            <div class="pmbb-header">
                                <h2><i class="zmdi zmdi-phone m-r-5"></i> Children Information</h2>
                            </div>
                            <div class="pmbb-body p-l-30">
                                <div class="form-group m-b-30" v-for="(child, i) in staff.children" :key="i">
                                    
                                    <button type="button" class="btn btn-danger btn-icon btn-remove" @click="removeChild(child.id, i)" >
                                        <i class="zmdi zmdi-close"></i>
                                    </button>
                                    
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Full Name</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <input type="text" class="form-control" v-model="child.name">
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Gender</dt>
                                        <dd>
                                            <div class="fg-line">
                                                <select class="form-control" v-model="child.gender">
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                </select>
                                            </div>
                                        </dd>
                                    </dl>  
                                    <dl class="dl-horizontal">
                                        <dt class="p-t-10">Date of Birth</dt>
                                        <dd>
                                            <birth-datepicker 
                                                v-model="child.dob" 
                                                class="form-control" 
                                                :high="false" 
                                                :closeOnSet="true" 
                                                :valueIsString="true" 
                                                :delimiter="'-'" 
                                                :attachment="'bottom-right'"/>
                                        </dd>
                                    </dl>                                    
                                </div>
                                <button type="button" class="btn btn-primary btn-sm" @click="addNewChild">Add New Child</button>
                            </div>
                        </div>


                        <!-- Submit -->
                        <div class="form-group m-l-30  m-b-30 m-t-30">
                            <button class="btn btn-success btn-icon-text m-l-30" :disabled="isProcessing">
                                <i class="zmdi zmdi-save"></i>
                                Update Staff
                                <span class="loader" v-if="isProcessing"></span>
                            </button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import birthDatepicker from 'vue-birth-datepicker'
    import 'vue-birth-datepicker/dist/vueBirthDatepicker.css'

    import VueMonthlyPicker from 'vue-monthly-picker'

    export default {
        data(){
            return {
                staffId: this.$route.params.staffId,
                myCroppa: {},
                monthLabels: this.$store.state.monthLabels,
                roles: [],
                staff: {},
                isProcessing: false,
                isFileChoosen: false,
                isSaving: false,

                newMyCroppa: {},
                imgUrl: '',
                
            }
        },
        created(){
            this.getStaff()
        },
        methods: {

            updateStaff(){
                this.isProcessing = true
                this.$http
                    .post("/staff/update", this.staff)
                    .then(response => {
                        if (response.data.code === 200) {
                            this.$notify.success("Staff updated successfully")
                        } else {
                            this.$notify.error("Staff failed to update")
                        }
                        this.isProcessing = false
                    })
                    .catch(error => {
                        this.$notify.error(error)
                    })
            },

            getStaff(){
                this.$http.get("/staff/edit", {
                    params: {
                        staffId: this.staffId
                    }
                }).then(response => {
                    this.staff = response.data.data
                })
            },

            addNewChild(){
                this.staff.children.push({
                    name: "",
                    gender: "",
                    dob: "",
                })
            },

            removeChild(id, i){
                if (typeof id === 'undefined') {
                    this.staff.children.splice(i, 1)
                } else {
                    this.$swal.fire({
                        type: 'warning',
                        title: 'REMOVE CHILD',
                        text: 'Are you sure you want to delete this child? This is not recoverable!',
                        showCancelButton: true,
                        focusConfirm: true,
                    })
                    .then(result => {
                        if (result.value) {
                            this.$http
                                .delete("/staff/deletechild", {
                                    data: {
                                        id
                                    }
                                })
                                .then(() => {
                                    this.staff.children.splice(i, 1)
                                })
                        }
                    })
                }
            },

            addNewRole(){
                this.staff.roles.push({
                    role: "",
                    started: "",
                    ended: "",
                    isCurrent: false,
                })
            },

            removeRole(id, i){ 
                if (typeof id === 'undefined') {
                    this.staff.roles.splice(i, 1)
                } else {
                    this.$swal
                        .fire({
                            type: 'warning',
                            title: 'REMOVE ROLE',
                            text: 'Are you sure you want to delete this role? This is not recoverable!',
                            showCancelButton: true,
                            focusConfirm: true,
                        })
                        .then(result => {
                            if (result.value) {
                                this.$http
                                    .delete("/staff/deleterole", {
                                        data: {
                                            id
                                        }
                                    })
                                    .then(() => {
                                        this.staff.roles.splice(i, 1)
                                    })
                            }
                        })
                }                
            },

            



            handleCroppaInit(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaFileChoose(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaFileSizeExceed(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaFileTypeMismatch(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleNewImage(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleImageRemove(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaMove(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },
            handleCroppaZoom(){
                this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            },




            // handleCroppaFileChoose(){
            //     this.isFileChoosen = true
            // },

            // handleCroppaInit(){
            //     this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            // },

            // handleCroppaMove(){
            //     this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            // },

            // handleCroppaZoom(){
            //     this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            // },

            // handleNewImage(){
            //     this.staff.photo = this.$refs.staffPhoto.generateDataUrl()
            // },
            
            removePhoto(photo){
                this.$swal.fire({
                    type: 'warning',
                    title: 'REMOVE PHOTO',
                    text: 'Are you sure you want to delete this child? This is not recoverable!',
                    showCancelButton: true,
                    focusConfirm: true,
                }).then(result => {
                    if (result.value) {
                        this.$http
                            .delete("/staff/deletephoto", {
                                data: {
                                    staffId: this.staffId,
                                    photo
                                }
                            })
                        this.$refs.staffPhoto.remove()
                        this.staff.photo = ''
                    }
                })
            },
            
            onInputMobile1({ number }) {
                this.staff.mobile1 = number.e164
            },

            onInputMobile2({ number }) {
                this.staff.mobile2 = number.e164
            },

            onInputTelephone1({ number }) {
                this.staff.telephone1 = number.e164
            },
            onInputTelephone2({ number }) {
                this.staff.telephone2 = number.e164
            },




            savePhoto() {
                let url = this.$refs.editStaffPhoto.generateDataUrl()
                if (!url) {
                    alert('no image')
                    return
                }
                this.isSaving = true
                this.$http
                    .post("/staff/savephoto", {
                        staffId: this.staffId,
                        photo: url,
                    })
                    .then(() => {
                        this.$notify.success("Photo saved successfully")
                        this.isFileChoosen = false
                        this.isSaving = false
                    })
            },

            selectPhoto(){
                this.$refs.editStaffPhoto.chooseFile()
            },
            
        },

        components: { 
            birthDatepicker,
            VueMonthlyPicker 
        },
    }
</script>

<style scoped>
dl:last-child {
    margin-bottom: 30px;
}
.item-marker {
    margin: 0 0 50px;
}
.item-marker:last-child {
    margin-bottom: 0 !important;
}
</style>